var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"elevation":"12","min-height":"600"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-title',[_vm._v("Stanze")])],1),_c('v-col',{staticClass:"pr-8",attrs:{"cols":"6","align":"right","align-self":"center"}},[_c('v-btn',{attrs:{"title":"Stanza virtuale dei beni arrivati","elevation":"2","to":"rooms/23210-V001"}},[_vm._v("Ricevimento")])],1)],1),_c('v-card-text',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('br'),_c('br'),_c('br'),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}}),_c('br'),_c('br'),_c('br')],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.rooms,"item-key":"COGNOME","search":_vm.search,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Stanze per pagina',
          itemsPerPageAllText: 'Tutte le stanze',
          itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
          pageText: '',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Stanze per pagina'"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":'/rooms/' + item.ID}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizza")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }