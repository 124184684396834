<template>
  <v-container>
    <v-card elevation="12" min-height="600">
      <v-row >
      <v-col cols="6">
      <v-card-title>Stanze</v-card-title>
      </v-col>
      <v-col class="pr-8" cols="6" align=right align-self=center>
      <v-btn title="Stanza virtuale dei beni arrivati" elevation="2" to="rooms/23210-V001">Ricevimento</v-btn>
      </v-col>
      </v-row>
      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular color="primary" indeterminate :size="70" :width="7" />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-data-table
          v-show="!loading"
          elevation="10"
          :headers="headers"
          :items="rooms"
          item-key="COGNOME"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Stanze per pagina',
            itemsPerPageAllText: 'Tutte le stanze',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Stanze per pagina'"
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessuna stanza </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessuna stanza </v-sheet>
          </template>
          <template v-slot:item.id="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :to="'/rooms/' + item.ID"
                >
                  <v-icon small> mdi-eye-outline </v-icon>
                </v-btn>
              </template>
              <span>Visualizza</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

//const axios = require('axios');

export default {
  name: "Rooms",
  data: () => {
    return {
      rooms: [],
      search: "",
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "ID",
        },
        {
          text: "Codice",
          align: "start",
          sortable: true,
          value: "CODICE_STANZA",
        },
        {
          text: "Edificio",
          align: "start",
          sortable: true,
          value: "NOME_EDIFICIO",
        },
        {
          text: "Piano",
          align: "start",
          sortable: true,
          value: "NUM_PIANO",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "RM_NAME",
        },
        {
          text: "Nome (cont)",
          align: "start",
          sortable: false,
          value: "LOCAL_NAME",
        },

        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "NOME_STANZA",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "id",
        },
      ];
    },
  },

  created: function () {
    this.loading = true;
    // console.log(process.env.VUE_APP_PATRIMONIO_API + "/rooms/list");
    // console.log(this.$store.state.authJwt);
    this.$http
      .get(process.env.VUE_APP_PATRIMONIO_API + "/rooms/list", {
        headers: {
          authorization: "Bearer " + this.$store.state.authJwt,
        },
      })
      .then((response) => {
        this.rooms = response.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {},
};
</script>